:root {--primary-color: #3b933f;--secondary-color: #343434; --accent-color: #17a2b8; --primary-color-shadow: 0 0 0 0.2rem #3b933f40;}
.maroon {--primary-color: #a52744;--secondary-color: #941b37;}
.mars {--primary-color: #f96332;--secondary-color: #e04511;}
.sky {--primary-color: #2748a5;--secondary-color: #1a388b;}
.sea {--primary-color: #27a582;--secondary-color: #148b6a;}
.ferrari {--primary-color: #d32f2f;--secondary-color: #b71c1c;}
.grape {--primary-color: #9C27B0;--secondary-color: #7B1FA2;}
.sea-gray {--primary-color: #455a64;--secondary-color: #37474f;}
.sea-gray-light {--primary-color: #607d8b;--secondary-color: #546e7a;}
.asopalav {--primary-color: #43A047;--secondary-color: #388E3C;}


.whatsapp-right { right: 15px; left: unset; } 
.whatsapp-left { left: 15px; right: unset; }


/* Backgrounds  */
.bg-gradient-primary { background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important }
.bg-gradient-secondary { background: linear-gradient(87deg, #f7fafc 0, #f7f8fc 100%) !important }
.bg-gradient-success { background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important }
.bg-gradient-info { background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important }
.bg-gradient-warning { background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important }
.bg-gradient-danger { background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important }
.bg-gradient-light { background: linear-gradient(87deg, #adb5bd 0, #adaebd 100%) !important }
.bg-gradient-dark { background: linear-gradient(87deg, #212529 0, #212229 100%) !important }
.bg-gradient-default { background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important }
.bg-gradient-white { background: linear-gradient(87deg, #fff 0, #fff 100%) !important }
.bg-gradient-neutral { background: linear-gradient(87deg, #fff 0, #fff 100%) !important }
.bg-gradient-darker { background: linear-gradient(87deg, #000 0, #000 100%) !important }


.bg-theme {background-color: var(--primary-color)!important;}
.bg-accent {background-color: var(--accent-color)!important;}
.text-theme {color: var(--primary-color)!important;}
.text-lighter {color: rgba(255, 255, 255, 0.6)!important;}


/* * {} */
html, body {font-family: "Poppins", sans-serif, 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif,Arial, sans-serif; font-size: 14px;scroll-behavior: auto !important;}
body {background: #fafafa;}
body.login-page {background: var(--primary-color);}
.fixed-footer {position: fixed;bottom: 20px;height: 40px;width: 100%;right: 0;bottom: 0;padding: 0 15px;background: #fff;display: flex;justify-content: center;align-items: center;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.fixed-footer p{ width: 100%;overflow: hidden;text-overflow: ellipsis;}
.link-unstyled, .link-unstyled:hover { text-decoration: none; }
.card-login {width: 380px; max-width: 90%;}
.btn.focus, .btn:focus, .btn:focus-visible,.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show>.btn-danger.dropdown-toggle:focus,.btn-close:focus{outline: 0 !important;box-shadow: none !important;}
input[type ="checkbox"]{accent-color: var(--primary-color);}
input[type ="radio"]{accent-color: var(--primary-color);}

/* ------------------------------------------------ MD Time Picker ------------------------------------------------ */

.mdtimepicker .mdtp__clock_holder .mdtp__clock .mdtp__digit.active span, .mdtimepicker .mdtp__clock_holder .mdtp__clock .mdtp__digit:not(.digit--disabled) span:hover,.mdtimepicker .mdtp__clock_holder .mdtp__clock .mdtp__digit.active:before,.mdtp__wrapper[data-theme=primary-color] .mdtp__clock .mdtp__am.active, .mdtp__wrapper[data-theme=primary-color] .mdtp__clock .mdtp__clock_dot, .mdtp__wrapper[data-theme=primary-color] .mdtp__clock .mdtp__pm.active, .mdtp__wrapper[data-theme=primary-color] .mdtp__time_holder{background-color: var(--primary-color) !important; }
.mdtimepicker .mdtp__wrapper{left: 50%;top: 50%;transform: translate(-50%, -50%);bottom: 0;height: fit-content;}
.twt-time-picker{padding-right: 30px; position: relative; }
.twt-time-picker-div{position: relative; }

.twt-time-picker-div::before{position: absolute; content: '\f017'; width: 40px; height: 100%; font: var(--fa-font-regular); z-index: 6; top: 50%; right: 0; transform: translateY(-50%); display: flex; align-items: center; padding-left: 10px;}

/* ------------------------------------------------ MD Time Picker ------------------------------------------------ */

/* ------------------------------------------------ show toggle ------------------------------------------------ */


.breadcrumb-wrapper {top:0; left: 0;right: 0; background-color: #fff;align-items: center;}
.breadcrumb-wrapper a {color: #333;}
.breadcrumb-wrapper a:hover{color: var(--primary-color);}
.btn:not(.btn-link):hover {box-shadow: 0 4px 10px -2px rgba(30,30,30,0.5);}
.btn-wide {width: 100px;}

.card-shadow { box-shadow: 0 2px 8px -2px rgba(0,0,0,.15)}
.card-full-height {height: 100%;}
.card-sp {position: relative;border: none;border-radius: 7px;}
.card-sp {box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);}
.card-sp:after {content:''; position: absolute; width: 50%; height: 100%; right: 0;top: 0;}
.card-sp .card-body {position: relative;}

.alertify-notifier .ajs-message {border: none!important; text-shadow: none!important; border-radius: 4px; box-shadow: 0 4px 8px -2px rgba(0,0,0,.5)}

/*overlay*/
.overlay-block {position: fixed;height: 100%;width: 100%;top: 0;left: 0;background-color: rgba(35, 35, 35, 0.75);z-index: 999999;color: #fff;}
.overlay-block>* {position: absolute;transform: translate3d(-50%, -50%, 0);top: 50%;left: 50%;}


/* dashboard */
.dash-links,.dash-links:hover {text-decoration: none;}
.stats-label {font-size: 55px;color: var(--primary-color);text-shadow: 2px 2px rgba(30,30,30,0.2);font-weight: 700;opacity: 0.6;}

/* custom-table */
.custom-table {min-width: 720px; width: 100%; border-collapse: collapse; border-spacing: 0; border: 1px solid #ddd; font-size: 0.75rem;}
.custom-table th,
.custom-table td {border: 1px solid #ddd; padding: 0.25rem 0.5rem; text-align: center;}
.custom-table th:first-child {min-width: 150px;width: 150px;}



/* print */
@media print {
  @page {margin: 0;}
  .wrapper * {box-shadow: none !important;}
  .wrapper {padding: 0!important;}
  .wrapper .content {padding: 1rem}
  .breadcrumb-wrapper {display: none;}
}

label.invalid-input {color: #dc3545; font-size: 14px; margin-bottom: 0;}


/* ------------------------------------------------  settings ------------------------------------------------*/

/* this css for chenge the text in custom-switch-button */

.custom-switch.custom-switch-enable-disable .custom-control-input~.custom-control-label span:before{content:'Disable';white-space:nowrap;}
.custom-switch.custom-switch-enable-disable .custom-control-input:checked~.custom-control-label span:before{content:'Enable'}


.custom-switch.custom-switch-enable-disable.custom-switch-yes-no .custom-control-input~.custom-control-label span:before{content:'No';white-space:nowrap;}
.custom-switch.custom-switch-enable-disable.custom-switch-yes-no .custom-control-input:checked~.custom-control-label span:before{content:'Yes'}

.form-check-input:checked~.form-check-label span:before{content:'Enable'}
.form-check-input~.form-check-label span:before{content:'Disable'}
.form-check-input:focus{box-shadow: none;}
/*custom-switch-button end*/

.sticky-div{position:sticky;bottom:0;box-shadow:none!important;text-align:center;display:inline-flex;align-items:center;width:100%;z-index:9;border:none;border-radius:0; padding: 10px; background: #fff; gap: 10px; border-top: 1px solid #ddd;}
.sticky-div .action-btn-grp{display: flex;align-items: center;gap: 10px;}
.modal .sticky-div{    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);}
.sticky-div-center{justify-content:center;}
.sticky-div-end{justify-content:flex-end;}
.custom-switch-table{display: flex;justify-content: center;align-items: center;}
.custom-switch-table .custom-switch{width:100px;}
.list-of-other-master-div .card {padding: 15px;box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);background: #fff;border: none;border-radius: 0;}
.my-delete-btn{display: flex;justify-content: center;align-items: center;border-radius: 50%;background-color: var(--primary-color);font-size: 15px;width: 30px;height: 30px;position: absolute;top: -12px;right: -10px;text-decoration: none;}
.card .card-img-top.choose_other_file_2-preview{height:270px;overflow:hidden;object-fit:cover;}

.fixed-footer p, .fixed-footer p a{font-size: 15px; font-weight: 400; line-height: 21px; color: #000; }
.fixed-footer p a:hover { color: var(--primary-color); }
.setting-part {display: flex;align-items: center;padding: 10px;background-color: #fff;z-index: 3;border: 1px solid #f1f1f1;position: sticky;top: 50px;left: 0;right: 0; overflow-x: auto;}
.setting-part .setting-link{background-color: var(--primary-color);color: #fff;border-radius: 3px;text-align: center;padding: 10px 8px;white-space: nowrap;margin: 0 10px;}
.setting-logo {width: 100%;object-fit: contain;margin: 0 auto;display: flex;height: 140px;}
.setting-remove-btn.remove-banner-btn {position: absolute;top: -15px;right: -2px;}
.remove-banner-btn .close-button {width: 30px;height: 30px;line-height: 30px;padding: 0;text-align: center;}
.fa-spin{color: #fff !important;}

 @media (max-width:991px){
  /*.upload-image  img{max-width:200px;margin:0 auto;}*/
}
 @media (max-width:767px){
  .card .card-img-top.choose_other_file_2-preview{height:200px;}
  .upload-image  img{max-width:280px;}
 }

 @media (max-width:575px){
  .upload-image  img{max-width:100%;}
 }


 .alertify .ajs-dialog { max-width: 600px; min-height: 122px; background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.2); -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%); box-shadow: 0 5px 15px rgb(0 0 0 / 50%); border-radius: 6px; }

.alertify .ajs-header { color: #333; border-bottom: 1px solid #e5e5e5; border-radius: 6px 6px 0 0; font-size: 18px; font-family: "Poppins", sans-serif !important; }
.alertify .ajs-body .ajs-content{padding-left: 0;}
.alertify .ajs-body{font-family: "Poppins", sans-serif !important;}
.alertify .ajs-footer { background-color: #fff; padding: 15px; border-top: 1px solid #e5e5e5; border-radius: 0 0 6px 6px; }
.ajs-message.ajs-visible{color: #fff; text-align: center;}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {overflow: auto;}
.user-main-div .table-responsive{overflow-x:unset;}
.horizontal-header .setting-part{top: 106px;}

/* ---------------------------------------------------------------------- datatable ---------------------------------------------------------------------- */
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  overflow: auto;
}
@media screen and (max-width: 767px){
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {text-align: left !important;}
}
.dtfh-floatingparenthead table.dataTable{border-collapse: collapse !important;}
.dataTable td{background: white !important;position: relative;}
.dataTables_scrollBody{height: auto !important;}
div.dataTables_wrapper div.dataTables_info{white-space: pre-wrap !important;}
#user-table_wrapper table.dataTable{border-collapse: collapse !important;}

/* ------------------------------------------------------ page not found css ------------------------------------------------------  */



.page-not-found-section {position: relative;overflow: hidden;background-color: white;display: flex;justify-content: center;align-items: center;font-family: Arial, Helvetica, sans-serif; padding: 70px 0;}

.page-not-found-section .page-not-found-content .number-text {display: flex;align-items: center;justify-content: center;margin: 0px 0;}
.page-not-found-section .page-not-found-content .number-text .text {font-size: 120px !important;font-weight: bold;line-height: 1;color: #3b933f;}
.page-not-found-section .page-not-found-content .number-text .smaily-image {width: 120px;margin: 0 10px;
color: #3b933f !important;filter: invert(46%) sepia(72%) saturate(431%) hue-rotate(73deg) brightness(90%) contrast(85%);}
.page-not-found-section .page-not-found-content .error-text {font-size: 77px;text-align: center;line-height: 1;font-weight: bold;text-transform: uppercase;color: #3b933f;letter-spacing: 3px;}
.page-not-found-section .page-not-found-content .normal-text {font-size: 28px;text-align: center;font-weight: bold;color: #3b933f;}
.page-not-found-section .page-not-found-content .links-pages {text-align: center;margin-top: 20px;}
.page-not-found-section .page-not-found-content .links-pages .common-link {display: inline-block;padding: 8px 15px;background-color: #3b933f;color: #fff;border-radius: 5px;min-width: 120px;text-decoration: none;border: 0;}
.page-not-found-section.page-not-found-content.links-pages.common-link.home-page-link {margin-right: 10px;}

@media (max-width: 767px) {
  .page-not-found-section .page-not-found-content .number-text .text {font-size: 150px;}
  .page-not-found-section .page-not-found-content .number-text .smaily-image {width: 134px;}
  .page-not-found-section .page-not-found-content .error-text {font-size: 55px;}
  .page-not-found-section .page-not-found-content .normal-text {font-size: 22px;}

}
@media (max-width: 375px) {
  .page-not-found-section .page-not-found-content .number-text .smaily-image {width: 100px;}
  .page-not-found-section .page-not-found-content .number-text .text {font-size: 110px;}
  .page-not-found-section .page-not-found-content .error-text {font-size: 44px;}
  .page-not-found-section .page-not-found-content .normal-text {font-size: 18px;}
}



/* File Preview */

/* File Preview  single*/

.file-name-preview {width: 100%;height: 50px; border-radius: 4px;border: 1px solid #ddd; display: flex; align-items: center; justify-content: space-between;padding: 5px; margin-top: 10px;} 
.file-name-preview .file_upload_with_preview_delete-preview{width: calc(100% - 30px); overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.file-name-preview button{ border-radius: 50%;width: 30px;font-size: 16px;display: flex;justify-content: center;align-items: center;height: 30px;line-height: 30px;}  

/* image Name Preview  Multiple */

.file-preview-mrow .file-preview-mdiv{min-width: 33.33%;max-width: 33.33%;padding: 0 15px;} 

/* File Name Preview  Multiple */

.file-name-preview-mrow .file-preview-mdiv{min-width: 50%;max-width: 50%;height: 50px;border-radius: 4px;display: flex;align-items: center;justify-content: space-between;padding: 0 15px;} 
.file-name-preview-mrow .file-preview-mdiv .upload-main-image{display: flex;align-items: center;justify-content: space-between;padding: 5px;width: 100%;word-wrap: break-word;word-break:break-all;border: 1px solid #ddd; }
.file-name-preview-mrow .file-preview-mdiv .upload-main-image .file-name-preview-label{ width: calc(100% - 30px); overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.file-name-preview-mrow .file-preview-mdiv .upload-main-image .close-buttons{position: relative;top:initial;left: initial; top: 0; right: 0; min-width: 30px; width: 30px;}
.file-name-preview-mrow .file-preview-mdiv .file-name-preview-label{margin: 0;}


@media (max-width: 992px) {
  .file-preview-mrow .file-preview-mdiv{min-width: 50%;max-width: 50%;} 
}

@media (max-width: 568px) {
  .file-preview-mrow .file-preview-mdiv{min-width: 100%;max-width: 100%;} 
  .file-name-preview-mrow .file-preview-mdiv{min-width: 100%;max-width: 100%;} 
  .fc .fc-toolbar{flex-direction: column-reverse; }
  .fc-toolbar-chunk{margin-top: 5px;}
	.fc .fc-button{padding: .4em .45em !important; font-size: .84em !important;}
	.fc .fc-toolbar-title {font-size: 1.45em !important;}
	.fc .fc-toolbar.fc-header-toolbar{margin-bottom: 1rem !important;}
	.fc-day  { min-height: 45px; }
	.fc-time-grid .fc-slats td { height: 3.5em; }
}


/*---------------------bootstrap vs-5 changes--------------------*/
.twt-disabled{pointer-events:none;opacity:0.7;}
.btn-theme:hover{background-color: var(--primary-color) ;}
.btn-theme:active{background-color: var(--primary-color) !important;}
.btn-info{color: #fff;}
.btn-info:hover{color: #fff;}
.page-link:focus{box-shadow: none;}
.twt-custom-switch{display: flex; justify-content: flex-start;}
.twt-custom-switch .form-check-label{margin-left: 7px;}
.wrapper .sidebar .sidebar-nav a {padding:0.5rem 1rem; width: 100%;}
.wrapper .navbar {padding: 10px 15px;}
.form-group{margin-bottom:15px;position: relative;}
a{text-decoration: none;}
.gallery-image-div{position: relative;}

.all-elements-page .twt-custom-switch{ justify-content: left;}
body.modal-open{overflow: hidden; padding-right: 0px;}

/* .row>*{padding-left: 7.5px;padding-right: 7.5px;} */

.custom-file {position: relative;display: inline-block;width: 100%;height: calc(1.5em + 0.75rem + 2px);margin-bottom: 0;}

.custom-file-input {position: relative;z-index: 2;width: 100%;height: calc(1.5em + 0.75rem + 2px);margin: 0;opacity: 0;}
.custom-file-label {border-color: #e8e7e7;}
.custom-file-label {position: absolute;top: 0;right: 0;left: 0;z-index: 1;height: calc(1.5em + 0.75rem + 2px);padding: 0.375rem 0.75rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;border: 1px solid #ced4da;border-radius: 0.25rem;}


.custom-file-button input[type="file"] {margin-left: -2px !important;}
.custom-file-button input[type="file"]::-webkit-file-upload-button {display: none;}
.custom-file-button input[type="file"]::file-selector-button {display: none;}
.custom-file-button:hover label {background-color: #dde0e3;cursor: pointer;}


a:not([href]):not([tabindex]) {color: inherit;text-decoration: none;}
.form-control[readonly]{background-color: #e9ecef;opacity: 1;}

.custom-file-label::after {position: absolute;top: 0;right: 0;bottom: 0;z-index: 3;display: block;height: calc(1.5em + 0.75rem);padding: 0.375rem 0.75rem;line-height: 1.5;color: #495057;content: "Browse";background-color: #e9ecef;border-left: inherit;border-radius: 0 0.25rem 0.25rem 0;}

.custom-file-input {position: relative;z-index: 2;width: 100%;height: calc(1.5em + 0.75rem + 2px);margin: 0;opacity: 0;cursor: pointer;}

.footer-map iframe{width: auto !important; height: 150px !important; border: 0 !important; border-radius: 10px;}
.form-check-input:checked{background-color: var(--primary-color);border-color: var(--primary-color);}
thead th{vertical-align: middle !important;}
input:read-only:focus{border-color:#e9ecef ;accent-color:var(--primary-color);}

.select2-mil-main{display: flex; align-items: center; justify-content: space-between; gap: 10px;}
.select2-mil-link-div{width: 100px;display: flex; align-items: center; justify-content: end;gap: 10px;}
.select2-mil-link{font-size: 14px; line-height: normal; font-weight: 500;}
.page-not-found-section{background-color: #fafbff;display: flex;align-items: center;justify-content: center;position: relative; padding: 70px 0;}
.form-switch .form-check-input:focus { background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/></svg>"); }
.form-switch .form-check-input:checked{ background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(255 255 255)'/></svg>"); }
.select2-selection__choice { background: #DDDDDD; color: #000; }
.btn-close:focus{box-shadow: none;}

/* File Upload CSS  */
.custom-file-upload .custom-file-input, .custom-file-upload .custom-file{height: 40px;cursor: pointer;}
.custom-file-upload .custom-file-label{padding: 10px 12px;border-radius: 4px;border: 1px dashed #999999;font-size: 14px;font-weight: 400;line-height: 20px;text-align: left;height: 40px;display: flex;align-items: center;gap: 0 4px;cursor: pointer;color: rgb(0 0 0 / 40%);transition: all 0.2s ease-in-out;z-index: 11;}
.custom-file-upload .custom-file-label:hover{border-color: var(--primary-color);}
.custom-file-upload .custom-file-input:focus~.custom-file-label{border-color: #999999;}
.custom-file-upload .custom-file-label .border-text{color: #000;text-decoration: underline;}
.custom-file-upload .custom-file-label .icon{display: flex;font-size: 16px;margin-right: 6px;color: #000;}
.custom-file-upload .custom-file-label::after{display: none;}
.custom-file-upload .preview-image-div{width: fit-content;position: relative;}
.custom-file-upload .preview-image-div .remove-banner-btn{position: absolute;right: 4px;top: 4px;}
.custom-file-upload .preview-image-div .remove-banner-btn .close-button{width: 16px;height: 16px;border-radius: 100px;background: #FF0000;display: flex;align-items: center;justify-content: center;}
.custom-file-upload .preview-image-div .remove-banner-btn .close-button .icon{display: flex;align-items: center;justify-content: center;font-size: 11px;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);line-height: 0;}
.custom-file-upload .preview-image{width: 65px;height: 65px;border-radius: 4px;object-fit: cover;}


/* //------------------------ Prime React CSS ------------------------\\ */
.p-component{font-family: "Poppins", sans-serif !important;}
.blank-label .form-label{white-space: pre;}

/* Data Table  */
.react-table table th, .react-table table td{padding: 10px; font-size: 13px; line-height: 19px; font-weight: 500;}
.react-table table{border-top: 1px solid #DEE2E6 !important;border-bottom: 1px solid #DEE2E6 !important;}
.react-table tbody, .react-table td, .react-table tfoot, .react-table th, .react-table thead, .react-table tr{border-color: #DEE2E6;border-style: solid;}
.react-table thead > tr > th{border-width: 1px 1px 1px 1px;background-color: #f1f1f1;}
.react-table td{border-width: 0 1px;}
.react-table tbody tr{border-width: 1px 0;}
.react-table .p-paginator{display: flex;flex-direction: row;flex-wrap: nowrap;margin-top: 8px;justify-content: end;}
.react-table .p-dropdown{width: fit-content;}
.react-table .p-inputtext{display: flex;align-items: center;justify-content: center;}
.react-table .p-datatable-emptymessage td{text-align: center;}
.react-table .p-column-header-content{justify-content: space-between;}
.react-table .p-datatable-scrollable-table > .p-datatable-thead{top: -1px !important;}
.react-table .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{background: var(--primary-color);color: #fff;border-color: var(--primary-color);}
.p-datatable .p-datatable-thead > tr > th{font-size: 13px;line-height: 19px; font-weight: 500;color: #000;}
.p-datatable .center-text .p-column-header-content{justify-content: center;text-align: center;}
.p-datatable .p-sortable-column:focus-visible{box-shadow: none;}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon{color: var(--primary-color);}
@media (max-width:576px) {
  .react-table .p-paginator .p-paginator-element{width: 30px;height: 30px;min-width: 30px;}
}

/* Input Field  */
.p-inputtext{padding: 10px 12px;width: 100%;border-color: #DDDDDD;border-radius: 4px;font-size: 14px;line-height: 18px;font-weight: 400;color: #000;font-family: "Poppins", sans-serif, 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif,Arial, sans-serif; }
.p-inputtext.p-dropdown-label{font-size: 13px;}
.p-inputtext::placeholder{color: rgb(0 0 0 / 40%);}
.p-inputtext:enabled:focus{box-shadow: none;border-color: var(--primary-color);}
.p-inputtext:enabled:hover{border-color: var(--primary-color) !important;}
.p-invalid .p-inputtext, .p-invalid.p-inputtext{border-color: #dc3545 !important;}
.p-invalid .p-inputtext:hover, .p-invalid.p-inputtext:hover{border-color: #dc3545 !important;}
.p-invalid.p-inputwrapper-focus .p-inputtext{border-color: #dc3545 !important;}


/* Single Dropdown  */
.p-dropdown{width: 100%;border-color: #ddd;border-radius: 4px;}
.p-dropdown .p-dropdown-trigger{color: #000000;}
.p-dropdown-items, .p-multiselect-panel .p-multiselect-items{padding: 0;margin-bottom: 0;}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{padding: 5.250px 10.5px;word-wrap: break-word;word-break: break-all;white-space: pre-wrap;}
.p-dropdown:not(.p-disabled).p-focus, .p-multiselect:not(.p-disabled).p-focus{box-shadow: none;border-color: var(--primary-color);}
.p-dropdown:not(.p-disabled):hover, .p-multiselect:not(.p-disabled):hover{border-color: var(--primary-color);}
.p-invalid.p-dropdown{border-color: #dc3545;}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover{background: #e9ecef;color: #000;}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus{background: var(--primary-color);color: #fff;}

.p-multiselect.p-invalid.p-component:hover, .p-invalid.p-dropdown:hover{border-color: #e24c4c;}

/* Multiple Select Dropdown  */
.p-multiselect{width: 100%;}
.p-multiselect-label{display: flex;gap: 5px;flex-wrap: wrap;padding: 10px 12px;width: 100%;border-color: #DDDDDD;border-radius: 4px;font-size: 14px;line-height: 18px;font-weight: 400;color: #000;}
.p-multiselect .p-multiselect-trigger{color: #000;}
.p-multiselect-token{padding: 1px 8px;gap: 5px;}
.p-multiselect-token-label{max-width: 200px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.p-multiselect-panel .p-multiselect-header{padding: 4px 10px;}
.p-multiselect-panel .p-multiselect-items{padding-left: 0;margin-bottom: 0;}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item{padding: 5.250px 10.5px !important;gap: 0 7px;word-wrap: break-word;word-break: break-all;white-space: pre-wrap;}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus{background: #fff;}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{color: var(--primary-color);background: transparent;}
.p-multiselect-panel .p-checkbox.p-highlight .p-checkbox-box{border-color: var(--primary-color);}
.p-multiselect-panel .p-multiselect-select-all{display: flex;align-items: center;justify-content: center;}
.p-multiselect-panel .p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon{width: 12px;height: 12px;font-size: 12px;}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus{background: #fff;}
.p-multiselect-panel .p-checkbox .p-checkbox-box{width: 16px;height: 16px;border: 1px solid #000;border-radius: 4px;}
.p-multiselect-panel .p-checkbox > .p-checkbox{display: flex;align-items: center;justify-content: center;margin-right: 0;width: 16px !important;height: 16px !important;}
.p-dropdown-empty-message{padding: 0.75rem 1.25rem;}
.p-dropdown-clear-icon, .p-dropdown-filter-icon, .p-dropdown-filter-clear-icon{transform: translate(0,-50%);margin-top: 0;}
.p-dropdown-filter{padding-right: 30px !important;}
.p-dropdown-panel .p-dropdown-header{border-radius: 0;padding: 10px;}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {color: #fff;background: var(--primary-color);}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus{background: transparent;}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus:hover{background: #e9ecef;color: #000;}

.p-dropdown.p-disabled{background: #F1F1F1;}
.p-dropdown.p-disabled .p-dropdown-label.p-inputtext{color: rgb(0 0 0 / 70%);}

/* Radio Button & Checkbox Button  */
.prime-checkbox-mdiv{min-height: 40px;}
.p-checkbox{width: 16px;height: 16px;margin: 0;}
.p-checkbox .p-checkbox-box{width: 16px;height: 16px;border-radius: 4px;border: 1px solid #000;}
.prime-radio-mdiv, .prime-checkbox-mdiv{display: flex;align-items: center;flex-wrap: wrap;gap: 15px;}
.prime-radio-mdiv .prime-radio, .prime-checkbox-mdiv .prime-checkbox{display: flex;align-items: center;gap: 7px;}
.prime-checkbox-label{font-size: 14px;font-weight: 400;line-height: 20px;text-align: left;color: #000;}
.p-radiobutton.p-highlight .p-radiobutton-box{border-color: var(--primary-color);background: var(--primary-color);}
.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box{border-color: var(--primary-color);}
.p-checkbox.p-highlight .p-checkbox-box{background: var(--primary-color);border-color: var(--primary-color);}
.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box{border-color: var(--primary-color);}
.p-inputswitch.p-highlight .p-inputswitch-slider{background: var(--primary-color);}
.p-inputswitch{width: 28px;height: 14px;}
.p-inputswitch .p-inputswitch-slider:before{width: 12px;height: 12px;left: 0px;margin-top: -6px;}
.p-inputswitch.p-highlight .p-inputswitch-slider:before{transform: translateX(14px);}

/* Custom Radio Button  */
.custom-radio-mdiv{display: flex;width: 100%;padding: 5px 7px;border-radius: 7px;background: #F1F1F1;gap: 0 15px;}
.custom-radio-mdiv .custom-radio{position: relative;width: 100%;}
.custom-radio-mdiv .custom-radio .p-radiobutton{position: absolute;width: 100%;height: 100%;opacity: 0;}
.custom-radio-mdiv .custom-radio .prime-radio-label{font-size: 13px;font-weight: 400;line-height: 18px;text-align: center;color: #000000;max-width: 100%;padding: 6px 10px;border-radius: 7px;background: transparent;transition: all 0.2s ease-in-out;width: 100%;}
.custom-radio-mdiv .custom-radio .p-radiobutton.p-highlight ~ .prime-radio-label{background: var(--primary-color);color: #fff;}

/* Password  */
.p-password{width: 100%;}
.p-password-panel{padding: 10px;border-radius: 0 0 6px 6px;}
.p-input-icon-right > svg{transform: translate(0, -50%) !important;}
.p-input-icon-right > svg:focus{outline: 0 !important;}
.p-inputtext.p-password-input{padding-right: 40px;}
.p-password.p-input-icon-right > svg{right: 14px;top: 50%;margin: 0;}

/* Datepicker  */
.p-calendar{width: 100%;}
.p-datepicker{width: 265px !important;}
.p-datepicker:focus{box-shadow: none;}
.p-datepicker{padding: 10px;display: flex;flex-direction: column;gap: 5px 0;z-index: 1060 !important;}
.p-datepicker-header{padding-bottom: 5px;padding-top: 0;padding-left: 0;padding-right: 0;}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus{box-shadow: none;}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus{box-shadow: none;}
.p-datepicker table td > span.p-highlight{background: var(--primary-color);color: #fff;}
.p-datepicker table td > span{padding: 0 !important;}
.p-datepicker-buttonbar .p-button{padding: 0;}
.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next{padding: 0;}
.p-datepicker-buttonbar{padding-top: 5px;padding-bottom: 0;}
.p-datepicker table td{padding: 0;}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month{padding-top: 0;padding-bottom: 0;}
.p-datepicker-group{display: flex;flex-direction: column;gap: 5px 0;}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled){color: #000;}
.p-datepicker-buttonbar .p-button.p-button-secondary:enabled:focus{box-shadow: none;}
.p-datepicker-buttonbar .p-button.p-button-secondary.p-button-text:not(:disabled):hover{background: #fff;}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus{box-shadow: none;}
.p-datepicker table td > span:focus{box-shadow: none;}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover{color: var(--primary-color);}
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext{box-shadow: none;border-color: var(--primary-color);}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight, .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight{background: var(--primary-color);color: #fff;}
.p-datepicker table th > span{display: flex;align-items: center;justify-content: center;}
.p-datepicker table th{padding: 0;}
.calendar-icon{position: absolute;right: 12px;top: 50%;transform: translateY(-50%);display: flex;font-size: 16px;color: #000;}

/* File Upload  */
.p-fileupload .p-fileupload-buttonbar{padding: 1rem;}
.p-fileupload .p-fileupload-buttonbar .p-button{padding: 0.75rem 1.25rem;}
.p-fileupload .p-badge{padding: 0 .5rem;margin: 0.5rem;}
.p-fileupload .p-button .p-button-icon-left{margin-right: 0.5rem;}
.p-fileupload .p-fileupload-content{padding: 1rem;}
.p-fileupload .p-fileupload-row > div{padding: 1rem 1rem;}

.seq-th-btn svg.p-icon{user-select: none;-webkit-touch-callout: none;-webkit-user-select: none;width: 19px;height: 16px;}

/* CK Editior  */
.ck-editor__editable{min-height: 200px;}
@media (max-width:1600px) {
  .custom-radio-mdiv{gap: 0 10px;}
  .custom-radio-mdiv .custom-radio .prime-radio-label{padding: 6px;font-size: 12px;}
}
@media (max-width:991px) {
  .blank-label .control-label{white-space: unset;display: none;}

  .custom-file-upload .preview-image-div{margin: auto;}
}
@media (max-width:576px) {
  /* Multiple Select Dropdown  */
  .p-multiselect-token-label{max-width: 150px;}

  .react-table .p-paginator{flex-wrap: wrap;justify-content: center;gap: 15px 0;}
}

/* //------------------------ Prime React CSS - END ------------------------\\ */


/* Loader CSS For React  */
.loader-showing{overflow: hidden;position: relative;}
.loader{width: 100%;height: 100vh;min-height: 100vh;display: flex;justify-content: center;align-items: center;position: fixed;top: 0;bottom: 0;left: 0;right: 0;background-color: rgba(30, 30, 30, 0.20);z-index: 9999;overflow: hidden !important;}
.loader--ring div {border-color: #fff transparent transparent transparent !important;}
.loader--ring {display: inline-block;position: relative;height: 45px;width: 45px;}
.loader--ring div {display: block;position: absolute;animation: loader--ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;border: 6px solid #fff;border-radius: 50%;box-sizing: border-box;margin: 6px;height: 51px;width: 51px;}
.loader--ring div:nth-child(1) {animation-delay: -0.45s;}
.loader--ring div:nth-child(2) {animation-delay: -0.3s;}
.loader--ring div:nth-child(3) {animation-delay: -0.15s;}
@keyframes loader--ring {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}