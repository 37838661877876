@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html, body {font-family: "Poppins", sans-serif;background-color: #f1f1f1;}
.alertify{font-family: "Poppins", sans-serif !important;}
/* @media (min-width: 992px) {.container-fluid {max-width: 1410px;}
} */

/* calculated as screen height(100vh) - footer height(283px) - navbar height(49px) */

main {min-height: calc(100vh - 60px) !important;min-height: 100vh;}
.btn-theme {background-color: var(--primary-color) !important;border-color: var(--primary-color);}
.text-shadow {text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);}
.navbar-toggler:focus {outline: none;box-shadow: none;}
.link-unstyled, .link-unstyled:hover {text-decoration: none;}
.btn-span, .btn-span:hover {pointer-events: none;}
label.invalid-input {color: #dc3545; font-size: 13px; margin-bottom: 0;font-weight: 400;}


/* All Elements ===================================================================================================== */

.form-control,.custom-select,.custom-file-label {border-color: #e8e7e7;}

.user-image {height: 250px;width: 250px;object-fit: cover;object-position: center;margin-bottom: 1.5rem;border: 2px solid #999;}
.user-image.listing-img {height: 150px;width: 150px;border-width: 1px;}
@media (max-width: 767px){
	.user-image.listing-img {height: 75px;width: 75px;border-width: 1px;}}
.userImage_control {position: absolute;height: 0;width: 0;opacity: 0;visibility: hidden;}

.userImage_control_label {height: 54px;width: 54px;border-radius: 50%;background: #ddd;line-height: 50px;text-align: center;border: 2px solid #999;position: absolute;bottom: 0;left: 50%;transform: translateX(-50%);}
.userImage_control_label .fas {line-height: inherit;}
.mw-300 {max-width: 300px;}


/* this css for chenge the text in custom-switch-button */


.custom-control-input:checked~.custom-form-label span:before{content:'Enable'}
.custom-control-input~.custom-form-label span:before{content:'Disable'}

/*custom-switch-button end*/

/* select2 */

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple{ border-top-left-radius: 0; border-top-right-radius: 0;}
.select2-container--bootstrap-5.select2-container--focus .select2-selection, .select2-container--bootstrap-5.select2-container--open .select2-selection, .select2-container--default.select2-container--focus .select2-selection, .select2-container--default.select2-container--open .select2-selection{border-color: var(--primary-color);outline: none; box-shadow: none; }
.select2-container--bootstrap-5 .select2-dropdown{border-color: var(--primary-color);}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted){background: var(--primary-color); color: #fff;}
.select2-container--default .select2-selection--single{height: calc(1.5em + 0.75rem + 2px);}
.select2{min-width: 100% !important;}
.select2-container.select2-container--default.select2-container--open{z-index: 999999;}
body:not(.modal-open) .select2-container--open {z-index: 99;}

/* select2 end */


/* Custom Radio Button */

.custom-radio-btn-outline {border: 1px solid var(--primary-color);color: var(--primary-color);cursor: pointer;}
.btn-group-toggle .btn {padding: 0;}
.btn-group-toggle .custom-span {display: block;padding: .375rem .61rem}
.btn-group-toggle input[type="radio"]:checked+span {color: #fff;background-color: var(--primary-color);border-radius: 3.5px;}

/* Custom checkbox */

.radio-boxes .radio-box {position: relative;}

.radio-boxes .radio-box input[type="radio"],
.radio-boxes .radio-box input[type="checkbox"] {opacity: 0;position: absolute;top: 0;left: 0;}

.radio-boxes .radio-box .form-check {padding-left: 0;}

.radio-boxes .radio-box input[type="radio"]:focus~.form-check-label,
.radio-boxes .radio-box .form-check-label:hover,
.radio-boxes .radio-box input[type="checkbox"]:focus~.form-check-label,
.radio-boxes .radio-box .form-check-label:hover {border-color: var(--primary-color);color: var(--primary-color);}

.radio-boxes .radio-box input[type="radio"]:checked~.form-check-label,
.radio-boxes .radio-box input[type="checkbox"]:checked~.form-check-label {border-color: var(--primary-color);}

.stock-btn {width: 100%;border: 1px solid var(--primary-color);border-radius: 0;color: var(--primary-color); cursor: pointer;}

.radio-boxes .radio-box input[type="radio"]:checked~.form-check-label,
.radio-boxes .radio-box input[type="checkbox"]:checked~.form-check-label {border-color: var(--primary-color);background-color: var(--primary-color);color: #fff !important;}

/* Custom checkbox end*/

/* Multiple Image Selection */
.upload-main-image{position: relative; width: fit-content;height: fit-content;}

.upload-main-image .close-buttons {position: absolute;right: -10px;top: -10px;z-index: 1;}
.upload-main-image .close-buttons button.button-gallery.btn-danger.button-round {border-radius: 50%;width: 30px;font-size: 16px;display: flex;justify-content: center;align-items: center;height: 30px;line-height: 30px;}

/* Multiple Image Selection end */


/*.custom-file-input*/

.custom-file-input:focus~.custom-file-label{border-color: var(--primary-color); box-shadow:none;}
.form-select:focus,.form-control:focus,.select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field:focus{border-color: var(--primary-color);outline: 0;box-shadow: none;}
.custom-form-label::after{width: 13px; height: 13px; background-size: 9px; }
.custom-form-label::before{width: 13px; height: 13px; }

/* Extra */
.btn:not(.btn-link):hover {box-shadow: none;}
.button-sticky-submit{position: fixed;bottom: 0;right: 0;left: 250px;padding: 10px;background: #fff;z-index: 9;border-top: 1px solid #ddd;}

@media(min-width:992px){
	/* .wrapper.toggled .sidebar .sidebar-nav a .nav-text{display: none;} */
	/* .wrapper.toggled .fixed-footer p{display: none;} */
	.toggled .sidebar .sidebar-nav .nav-link{text-align: center;}
	.toggled .sidebar .sidebar-nav a.text-dark i.fa-fw{margin-right: 0;}
	.toggled .button-sticky-submit {left: 70px;}
  .navbar .logout{margin-left: 15px; margin-right: 10px;}
}
@media (max-width:991px){
.login-brand-logo{width: 50% !important;}
.horizontal-header .setting-part{top: 64px;}
.settings-cards .sticky-div{left: 0;}
.button-sticky-submit{left: 0;right: 0;}

}




/* All Elements end ===================================================================================================== */



/* css */

:root{--primary-color: #1462F3;--secondary-color: #343434; --accent-color: #17a2b8; --primary-color-shadow: 0 0 0 0.2rem #3b933f40;}

.text-theme{color: var(--primary-color) !important;}
.bg-theme{background-color: var(--primary-color);}
.btn:not(.btn-link):hover {box-shadow: none;}
.user-header-icon {min-height: 40px;background: var(--primary-color);min-width: 40px;margin-right: 15px;border-radius: 50%;display: flex;align-items: center;justify-content: center; }
.twt-navbar .user-icon-header {color: #fff;}
.twt-navbar.twt-navbar-common.nav-light .navbar-nav .nav-item .nav-link {font-size: 14px;color: #fff;padding: 10px 20px !important;position: relative;transition: all 0.4s ease-in-out;}
.twt-navbar.twt-navbar-common .navbar-nav .nav-item .nav-link, .twt-navbar.twt-navbar-common.fixed .navbar-nav .nav-item .nav-link{padding: 18px 15px;}
.login-admin-icon .logout-btn {color: var(--primary-color);font-size: 15px;font-weight: 500;}
.login-admin-icon .logout-btn .logout-icon {padding-right: 10px;font-size: 16px;color: var(--primary-color);}
.twt-navbar.twt-navbar-common .dropdown-menu .dropdown-item:hover {background: var(--primary-color);color: #fff !important;}
.twt-navbar.twt-navbar-common .dropdown-menu .dropdown-item:hover .icon-dropdown{color: #000 !important;}
.twt-navbar.twt-navbar-common .navbar-slide .dropdown-menu{top: 42px;}
.body-form-info .form-label{display: block;font-size: 15px;}
.body-form-info .form-control:focus{border-color: #000;box-shadow: none;}
.form-control {font-size: 14px;}
.body-form-info button:focus{outline: none;}
.header-title {font-size: 18px;line-height: 26px; font-weight: 500;text-transform: capitalize;}
.login-btn .dropdown-toggle::after{display: none;}
.login-btn .custom-dropdown-toggle:hover {color: var(--primary-color);text-decoration: none;}
.twt-navbar.twt-navbar-common .nav-bottom .nav-item:hover>a.nav-link, .twt-navbar.twt-navbar-common .navbar-nav .nav-item.active .nav-link {background-color: #fff;color: var(--primary-color);}

.twt-navbar-common .slide .nav-item.active {background: #fff;}
.chnage-password .body-form-info .form-group {margin-top: 0px;}
.bg-light-color {background: #f1f1f1;}

.chnage-password .form-control {font-size: 15px;}
.navbar-slide .dropdown .dropdown-toggle::after {content: "\f107";font-family: 'Font Awesome 5 Free';font-weight: 900;vertical-align: middle;border-top: 0;border-right: 0;border-bottom: 0;border-left: 0;}
.breadcrumb-wrapper .btn-light {padding: 6px 10px;font-size: 13px;line-height: 1.5;border-radius: 0;background: var(--primary-color);color: #fff !important;}
.table{margin-bottom: 0;}
.table td, .table th {font-size: 14px;}
.form-label {font-weight: 500;font-size: 13px;margin-bottom: 7px;line-height: 18px;color: #000;}
.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus {box-shadow: none;}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {color: #fff;background-color: var(--primary-color);border-color: var(--primary-color);}
.btn-info.focus, .btn-info:focus {box-shadow: none;}
.table-bordered thead td, .table-bordered thead th {border-bottom-width: 2px;font-size: 14px;font-weight: 500; color: #000;}
.breadcrumb-wrapper .category-add-link {color: #333;font-size: 14px;}
.breadcrumb-wrapper .breadcrumb-item{font-size: 14px;}
.breadcrumb-item.active {color: var(--primary-color);}
.page-item:last-child .page-link {border-top-right-radius: 0;border-bottom-right-radius: 0; color: #000;}
.page-item:first-child .page-link {margin-left: 0;border-top-left-radius: 0;border-bottom-left-radius: 0; color: #000;}
.paginate_button.disabled .page-link{color: var(--bs-pagination-disabled-color);}
.table-responsive .page-link{color: #000;}
.star {color: red;}
.update-section .body-form-info .form-group {margin-top: 0;margin-bottom: 33px;}
.update-section .twt-sticky-btn-div {width: 100%;margin: 0 15px;}
.add-user-section .btn.action-btn.btn-info {background: var(--primary-color);border-color: var(--primary-color);}
.page-item.active .page-link {z-index: 1;color: #fff;background-color: var(--primary-color);border-color: var(--primary-color);}
.dataTables_paginate,div.dataTables_wrapper div.dataTables_info {margin-top: 15px !important;}
table#user-table{width: 100% !important;}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle{background-color: var(--primary-color);}
.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show>.btn-light.dropdown-toggle:focus{box-shadow: none;}
.filter-result-wrapper table.dataTable{border-collapse: collapse !important;}
.button-actions-top-bar{padding: 8px 10px;border-radius: 4px; font-size: 14px; line-height: 20px; font-weight: 400; border: 0 !important;}
button:focus{outline: none;}
.twt-navbar.twt-navbar-common .dropdown-menu.login-dropdown .dropdown-item:hover{color: #000 !important;}
.message-div .alert {margin-bottom: 0; margin-top: 15px;}
/* .dataTables_scrollHeadInner, .dataTables_scrollHeadInner table{width: 100% !important; } */
.twt-filter-btn,.twt-filter-btn:active,.twt-filter-btn:hover .twt-filter-btn:focus-visible{background-color: #333 !important;}
.sort-order-div { padding-bottom: 0; margin-top: 10px; padding-top: var(--bs-card-spacer-y); }
@media (max-width: 1300px) {
  .datatable-new-bottom.bottom .dataTables_info{width: 100%;}
  .datatable-new-bottom.bottom .dataTables_length{margin-right: auto;}
}

@media (max-width: 1199px) {
.twt-navbar.twt-navbar-common.nav-light .navbar-nav .nav-item .nav-link{padding: 10px 10px !important;}
.datatable-new-bottom.bottom{justify-content: space-between;}
}

@media (max-width: 767px) {
	.card-login{width: 100%;}
	.col-sm-12.col-md-7:has(.dataTables_paginate){padding-left: 0;}
	.col-sm-12.col-md-5:has(.dataTables_info){padding-right: 0;}
}
@media (max-width: 575px) {
	.user-header-icon{min-width: 32px; min-height: 32px; margin-right: 6px;}
	.twt-navbar.twt-navbar-common .dropdown-menu{top: 35px;}.login-page:before{display: none;}.login-title{padding: 15px 20px 0px 0px;}
	.breadcrumb-wrapper .add-btn{padding: 8px;}
	/* .dropdown.admin-dropdown{position: absolute; right: 0px;} */
  .datatable-new-bottom.bottom{flex-direction: column; justify-content: center;}
  .datatable-new-bottom.bottom .dataTables_length{margin: auto;}
  .table-responsive .page-link{font-size: 13px; padding: 4px 8px;}
}

/* tables common */
.twt-table-header th{background: #E9ECEF; border-color: #DEE2E6;}
.table.table-dataTable.dataTable {border-collapse: collapse!important; border-spacing: 0;}
.table td {vertical-align: middle;}
.sr-col {max-width: 70px !important; width: 70px !important;min-width: 70px !important; text-align: center;}
.dataTable .sr-col {max-width: 50px !important; width: 50px !important;min-width: 50px !important; text-align: center;}
.wide-col {width: 250px;min-width: 250px;}
.status-col{max-width: 110px !important; width: 110px !important; min-width: 110px !important; text-align: center;}
.actions-col {max-width: 100px !important; width: 100px !important; min-width: 100px !important; text-align: center;}

.dataTable .status-col{max-width: 90px !important; width: 90px !important; min-width: 90px !important; text-align: center;}
.dataTable .actions-col {max-width: 110px !important; width: 110px !important; min-width: 110px !important; text-align: center;}
.actions-col-div{display: flex; justify-content: center; gap: 7px; flex-wrap: wrap;}
.dataTable td{background: #fff;}
.table td, .table th{padding: 6px 11px;}
.table{border-top: 1px solid #DEE2E6 !important; border-bottom: 1px solid #DEE2E6 !important;}

.datatable-new-bottom.bottom{display: flex;align-items: center;justify-content: end;gap: 15px;margin-top: 10px;width: 100%;flex-wrap: wrap;}
.datatable-new-bottom.bottom .dataTables_info{padding-top: 0;margin-top: 0 !important;margin-right: auto;font-size: 14px;font-weight: 400;line-height: 21px;color: #000;}
.dataTables_wrapper .page-item:first-child .page-link { border-top-left-radius: 5px; border-bottom-left-radius: 5px; }
.dataTables_wrapper .page-item:last-child .page-link { border-top-right-radius: 5px; border-bottom-right-radius: 5px; }
.form-select-sm{border-radius: 5px;}
.dataTables_length .form-select { padding: 4px 30px 4px 12px; }
.datatable-new-bottom.bottom .dataTables_paginate{margin-top: 0 !important;}
.twt-custom-switch.status-class{justify-content: center;}
table .action-btn { background: transparent; border: 0; color: #000; font-size: 18px !important; height: 18px; padding: 0 !important; width: 25px; display: inline-flex; align-items: center; justify-content: center; line-height: 0; }

.twt-fix-table{max-height:69vh;}
.twt-fix-table.table-responsive::-webkit-scrollbar{width: 10px;height: 10px !important;}
.twt-fix-table.table-responsive{border: 1px solid #dee2e6;}
.twt-fix-table.table-responsive table{margin-bottom: 1px;}
.twt-fix-table.table-responsive::-webkit-scrollbar-thumb {background-color: #c1c1c1;border-radius: 10px;}
.twt-fix-table.table-responsive .table thead tr th {position: sticky;top: -1px;background: #fff;}
.navbar .dropdown-toggle::after{content: "\f078";  font: var(--fa-font-solid); border: 0;margin: 0;}
.navbar .dropdown-toggle .img-user {max-width: 140px; display: flex; align-items: center; justify-content: flex-start;}
.navbar .dropdown-toggle .img-user span{overflow: hidden; text-overflow: ellipsis;}

table .action-btn.edit-btn{color: #3b933f;}
table .action-btn.edit-btn:focus{color: #3b933f;}
table .action-btn.delete-btn{color: #FF0000;}
table .action-btn.delete-btn:focus{color: #FF0000;}

.twt-fewcol-sticky{overflow: visible;}
.twt-fewcol-sticky .table thead {position: sticky;top: 49px; z-index: 3;}
.twt-fewcol-sticky .infinite-scroll-component {overflow: visible !important;}

.filter-result-wrapper .card{border: 0;}

/* TWT class list */


.twt-filter-icon{margin-right: 0px;}

.twt-add-icon{margin-right: 0px;}

.twt-search-div{margin-top: 10px; align-self: end;}


@media (min-width: 576px) {
  .twt-filter-icon, .twt-add-icon,.twt-sorting-icon{margin-right: 7px; font-size: 16px;}
}

@media (max-width:768px){
  .twt-fewcol-sticky{overflow-x:auto ;}
  .twt-fewcol-sticky .table thead {position: relative;top: 0; z-index: 1;}
}

@media (max-width:575px){
  .twt-filter-icon, .twt-add-icon, .twt-sorting-icon{    text-align: center;width: 1.25em;}
}


/* TWT class list end*/

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #ddd #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px !important;
    height: 5px !important;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
    border: 1px solid #ffffff;
  }
  .custom-file-label{white-space: nowrap; overflow-x: hidden;}
  .settings-cards .sticky-div{position: fixed;}


@media (max-width:768px){
  .twt-fewcol-sticky{overflow-x:auto ;}
  .twt-fewcol-sticky .table thead {position: relative;top: 0; z-index: 1;}

  }
 .horizontal-header .settings-cards .sticky-div{left: 0;}
 .horizontal-header main{min-height: calc(100vh - 110px) !important;}

.choose-preview-delete-div{position: relative;}

.table-responsive-date .bootstrap-datetimepicker-widget.dropdown-menu{position: fixed;  position: fixed !important;top: 50% !important;left: 50% !important;transform: translate(-50%, -50%);z-index: 9999 !important;}

.table-responsive-date>.bootstrap-datetimepicker-widget table td,
.table-responsive-date>.bootstrap-datetimepicker-widget table th{border: none !important; }
.table-responsive-date>.bootstrap-datetimepicker-widget tr:hover{background-color: #fff !important;}
.table-responsive-date>.bootstrap-datetimepicker-widget table th{background-color: #fff !important; font-weight: bold !important; font-size: 14px !important;}
.twt-sticky-btn-div {background-color: #fff;z-index: 98;position: sticky;bottom: 0px;left: 0;right: 0;}
.twt-btn-div{display: flex;align-items: center;justify-content: center;gap: 10px;padding-top: 10px;padding-bottom: 10px;margin-top: 5px;}


/* React Backend CSS  */

/* Login CSS  */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.login-page-section{height: 100vh;position: relative;background-position: center;background-size: cover; display: flex; align-items: center; justify-content: center;}
.login-page-section:before {position: absolute;content: "";top: 0;left: 0;width: 42%;height: 100%;background: var(--primary-color);z-index: 1;opacity: 0.6;}
.login-page-section .login-title {text-align: center;padding: 30px 0;font-weight: 600;font-size: 32px;margin-bottom: 0;line-height: 44px;}
.login-page-section .logo-img {margin-bottom: 25px;max-width: 130px;max-height: 130px;}
.login-page-section .form-items {padding: 0 130px;}
.login-page-section .overlay-title {text-align: center;font-size: 39px;font-weight: 700;color: #fff;}
.login-page-section .form-class {height: 100%;padding: 55px 0;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.login-page-section .submit-class {border-radius: 20px;border: 1px solid var(--primary-color);background-color: var(--primary-color);color: #FFFFFF;font-size: 14px;font-weight: 600;padding: 12px 45px;letter-spacing: 1px;text-transform: uppercase;transition: transform 80ms ease-in;margin: 15px auto 0 auto;display: table;width: 100%;box-shadow: 0 4px 10px -2px rgba(30,30,30,0.3);transition: all 0.3s ease-in-out;}
.login-page-section .submit-class:focus, .login-page-section .submit-class:active{border: 1px solid var(--primary-color);background-color: var(--primary-color);color: #fff;}
.login-page-section .form-group {margin-bottom: 15px;}
.login-page-section .login-items {background-color: #fff;border-radius: 10px;box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);position: relative;}
.login-page-section .overlay-items {height: 100%;width: 100%;position: relative;z-index: 1;}
.login-page-section .form-class .form-control {padding: 0px 15px;font-size: 15px;border: 1px solid var(--primary-color);border-radius: 50px;background: #fff;box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);height: 45px;}
.login-page-section .form-class .form-control[type="password"]{padding: 0px 45px 0 15px;}
.login-page-section .login-items .form-class-input {font-size: 14px;font-weight: 500;margin-bottom: 7px;}
.login-page-section .overlay-items .overlay {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 100%;height: 250px;display: flex;align-items: center;justify-content: center;color: #fff;}
.showPass {position: absolute;right: 14px;top: 50%;color: #374957;width: 25px;height: 25px;display: flex;align-items: center;justify-content: center;text-decoration: none;transform: translate(0, -50%);border: 0;background: transparent;padding: 0;margin: 0;}
.showPass:focus{outline: 0;}
.forgot-password{text-align: center;display: block;padding-top: 15px;color: var(--primary-color);font-weight: 500;font-size: 16px;text-decoration: underline;}
.login-page-section .password-icon{font-size: 75px;color: var(--primary-color);margin-bottom: 15px;}
.login-page-section .back-to-login{color: var(--primary-color);font-size: 16px;font-weight: 500;padding-top: 13px;display: block;}
.forgot-password:hover, .login-page-section .back-to-login:hover{color: #211f20;}

@media (max-width:1199px){
  .login-page-section .form-items {padding: 0 115px 0 115px;}
}
@media (max-width:991px){
  .login-page-section .login-page-items{position: relative;z-index: 11;}
  .login-page-section .form-items{padding: 0 155px 0 155px;}
  .login-page-section .overlay-items{display: none;}
} 
@media (max-width:767px){
  .login-page-section .form-items {padding: 0 70px 0 70px;}
}
@media (max-width:576px){
  .form-group .p-inputtextarea[rows="1"]{min-height: 70px;}
  .login-page-section .form-items {padding: 0 25px;}
  .login-page-section .login-title{font-size: 20px;padding: 10px 0 25px 0;}
  .login-page-section .form-class{padding: 45px 0;}
  .login-page-section .form-class .form-control{height: 43px;}
  .login-page-section .submit-class{margin: 20px auto 0 auto;}
}


main{min-height: unset !important;}
.breadcrumb-wrapper .breadcrumb{margin-bottom: 0;padding: 0;align-self: flex-end;}
.admin-dropdown .dropdown-toggle{background: transparent;border: 0;align-items: center;}
.pass-input{padding-right: 50px;}
.admin-dropdown{display: flex;align-items: center;padding: 0;}
.admin-dropdown:focus, .admin-dropdown:active, .admin-dropdown.show{border-color: transparent !important;}
.admin-dropdown .password {padding-right: 9px;font-size: 15px;color: var(--primary-color);}
/* .wrapper .sidebar .sidebar-nav .nav-link.active{color: var(--primary-color) !important;font-weight: 500;background: #fff !important;} */
.form-switch .form-check-input:focus{border: 1px solid rgba(0,0,0,.25);}
.form-switch .form-check-input:checked:focus{border: 1px solid var(--primary-color);}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){box-shadow: none !important;border-color: #ccced1 !important;}
.ck.ck-powered-by{display: none !important;}
.form-items .row .col-lg-12{padding: 0;}
.otp-page .form-group{margin-bottom: 10px;}


@media (max-width:1199px) {
  .backend-content{padding-left: 0;}
  .backend-content.toggled{padding-left: 0;}
}


.filter-result-wrapper .card, .add-form-container .card{background: transparent; border: 0; box-shadow: none !important;}
.filter-result-wrapper .card-body,.add-form-container .card-body{padding: 0;}
.add-form-container{padding: 15px;}
.filter-result-wrapper .p-datatable,.filter-result-wrapper .table-responsive ,.add-form-container .card{box-shadow: 0px 0px 16px 0px #00000014; padding: 15px; border-radius: 10px; background: #fff;}
.filter-result-wrapper .react-table .p-paginator{background: transparent;}
.filter-result-wrapper .p-paginator-element svg,.filter-result-wrapper .p-paginator-element svg path{ fill: #000; opacity: 1;}
.filter-result-wrapper .react-table .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{background: #333333;}
.filter-result-container .filter-result-wrapper{padding: 15px 0 0 0;}
.filter-result-container{padding: 15px !important; padding-top: 0 !important;}
.common-filter-collapse{margin-inline: -15px;}
.common-filter-collapse, .common-filter-card{border: 0; border-radius: 0;}
.common-filter-collapse .card-body{position: relative; padding: 15px 15px 0px;}
.common-filter-collapse .card-body::before{position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: 100%; height: 1px; background: #ddd; content: ''; padding: 0 15px; width: calc(100% - 30px);}
.breadcrumb-wrapper{border-bottom: 0 !important;  padding: 10px 15px; }

.twt-reset-btn,.twt-search-btn,.twt-submit-btn,.twt-close-btn, .twt-back-btn{display: inline-flex; align-items: center; justify-content: center; padding: 8px 10px; border-radius: 4px; border: 0 !important;font-size: 14px; font-weight: 400; line-height: 20px; }
.twt-back-btn, .twt-back-btn:hover, .twt-back-btn:focus, .twt-back-btn:focus-visible, .twt-back-btn:active{background: #777777;color: #fff !important;background-color: #777777 !important}
.twt-reset-btn,.twt-reset-btn:hover,.twt-reset-btn:focus-visible,.twt-reset-btn:active,.twt-close-btn,.twt-close-btn:hover,.twt-close-btn:focus-visible,.twt-close-btn:active {background: #777777 !important; color: #fff;}
.twt-reset-btn i,.twt-search-btn i{margin-right: 7px;}
.twt-submit-btn, .twt-close-btn{margin: 0;}

.modal-footer{background: #f1f1f1; border-top: 0; padding: 10px 15px;}

.modal-header{background: #f1f1f1; padding: 10px 15px;}
.modal-header .btn-close{opacity: 1;}
.modal-header .modal-title{font-size: 17px; font-weight: 500; line-height: 26px; color: #000;}
.modal-body{padding: 15px; padding-bottom:0;}
.p-dropdown-panel{z-index: 2000 !important;}
.prefix-group-div{width: 100px; max-width: 100px; min-width: 100px;}
.prefix-group-text{width: calc(100% - 100px);}

.prefix-group-mdiv .p-inputtext{border-top-left-radius: 0; border-bottom-left-radius: 0; }
.prefix-group-mdiv .p-dropdown{border-top-right-radius: 0; border-bottom-right-radius: 0; border-right-color: transparent;}
.modal-content{border-radius: 10px; border: 0;}
.modal-header{border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom: 0;}
.modal-footer{border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;}
.prefix-group-div .p-dropdown-label{padding: 0;}
.prefix-group-div .p-dropdown{padding: 10px 12px;}
.me-10{margin-right: 10px;}

@media (max-width:576px) {


    .prefix-group-div{width: 90px; max-width: 90px; min-width: 90px;}
    .prefix-group-text{width: calc(100% - 90px);}

}