/* 404 Admin Page CSS  */
.error-page-sec-admin {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-page-sec-admin .content {
    display: flex;
    align-items: center;
}

.error-page-sec-admin .content .error-text {
    margin-bottom: 0;
    font-size: 48px;
    font-weight: 500;
    line-height: 58px;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #e2e2e2;
}

.error-page-sec-admin .content .text {
    margin: 0;
    font-size: 18px;
    line-height: 32px;
    color: #212529;
}

@media (max-width:576px) {
    .error-page-sec-admin .content {
        flex-direction: column;
        gap: 15px 0;
        text-align: center;
        padding: 0 15px;
    }

    .error-page-sec-admin .content .error-text {
        padding-bottom: 15px;
        margin-bottom: 0;
        border-right: 0;
        border-bottom: 1px solid #e2e2e2;
        padding-right: 0;
        margin-right: 0;
        line-height: 1;
        width: 100%;
        text-align: center;
    }

    .error-page-sec-admin .content .text {
        font-size: 16px;
        line-height: 28px;
    }

    .error-page-sec-admin .content .error-text {
        font-size: 42px;
    }
}


/* 404 and 500 Error Pages CSS  */
.error-page-main{position: relative;overflow: hidden;background: #f1f1f1;}
.error-page-sec{padding: 35px 0;height: 100%;position: relative;overflow: hidden;}
.center-col{display: flex;align-items: center;justify-content: center;}
.error-page-sec .inner-content{display: flex;flex-direction: column;align-items: center;justify-content: center;text-align: center;}
.error-page-sec .inner-content .error-text{display: flex;align-items: center;gap: 0 20px;margin: 0;font-size: 140px;line-height: normal;font-weight: 600;margin-bottom: 10px;color:#212529e3;}
.error-page-sec .inner-content .smile-img{width: 130px;min-width: 130px;}
.error-page-sec .inner-content .heading{font-size: 34px;line-height: 44px;text-align: center;font-weight: 700;margin-bottom: 15px;}
.error-page-sec .inner-content .text{font-size: 16px;line-height: 26px;}
.error-page-sec .inner-content .primary-outline-btn{border-radius: 4px;padding: 12px 22px;font-weight: 400;font-size: 16px;line-height: 24px;color: #212629;transition: .3s ease-in-out;background: transparent;text-decoration: none;border: 1px solid #212629;margin-top: 10px;}
.error-page-main .smile-img-bg{position: absolute;right: -45px;bottom: -45px;width: 280px;transform: rotate(-40deg);filter: invert(83%) sepia(21%) saturate(0%) hue-rotate(302deg) brightness(95%) contrast(112%);opacity: 0.7;}
@media (max-width:576px) {
    .error-page-sec{padding: 35px 0;}
    .error-page-sec .inner-content .error-text{font-size: 100px;margin-bottom: 25px;gap: 0 15px;}
    .error-page-sec .inner-content .smile-img{width: 120px;min-width: 120px;}
    .error-page-sec .inner-content .heading{font-size: 28px;line-height: 38px;}
    .error-page-sec .inner-content .text{font-size: 14px;line-height: 24px;}
    .error-page-sec .inner-content .primary-outline-btn{margin: 0;padding: 10px 22px;font-size: 14px;line-height: 24px;}
    .error-page-main .smile-img-bg{display: none;}
}

/* Exception Page CSS  */
.custom-error-section{min-height:100vh; display:flex; align-items: center;}
.error-text{font-size: 60px;text-align: left;line-height: 1;font-weight: bold;text-transform: uppercase;color: #ff0000;letter-spacing: 3px;}
.common-link{display: inline-block;padding: 8px 15px;background-color: #3b933f;color: #fff;border-radius: 5px;min-width: 120px;text-decoration: none;border: 0;text-align: center;}
.common-link:hover{text-decoration:none;color: #fff;}
@media(max-width:767px){
    .man{width: 100%; object-fit: contain; margin-bottom: 20px;}
    .error-text{font-size: 40px;margin-top: 20px;}
}